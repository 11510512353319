var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con"},[_c('div',{staticClass:"top_box"},[_vm._m(0),_c('div',{staticClass:"top_box_info"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"download-btn",staticStyle:{"bottom":"0px"}},[_c('img',{attrs:{"src":require("../assets/download_ico.png")}}),_c('span',{staticStyle:{"letter-spacing":"2px"},on:{"click":_vm.downLoad}},[_vm._v("立即下载")])])])]),_vm._m(3),_vm._m(4),_c('div',{staticStyle:{"width":"100%","height":"80px"}}),_c('div',{staticClass:"foot"},[_vm._m(5),_c('div',{staticClass:"foot_btn"},[_c('img',{attrs:{"src":require("../assets/download_ico.png")}}),_c('span',{staticStyle:{"letter-spacing":"2px"},on:{"click":_vm.downLoad}},[_vm._v("立即下载")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_box_img"},[_c('img',{attrs:{"src":require("../assets/tkbb.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info_title"},[_c('img',{attrs:{"src":require("../assets/tk.png"),"alt":""}}),_c('h1',[_vm._v("抖音国际版正版下载-中文电脑版下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("版本：2024最新版")]),_c('li',[_vm._v("更新时间：2024-09-05")]),_c('li',[_vm._v("系统平台：Win7/WinVista/WinXP/Win8/Win10/Win11兼容软件")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_box"},[_c('div',{staticClass:"title"},[_vm._v("软件介绍")]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("从早上喝咖啡到晚上通勤，TikTok 提供的视频可以让您度过美好的一天。无论您是体育爱好者、旅行爱好者还是只是想找乐子，TikTok 上都能满足您的需求。")])]),_c('div',{staticClass:"title"},[_vm._v("软件截图")]),_c('div',{staticClass:"content_img"},[_c('img',{attrs:{"src":require("../assets/tk01.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/tk02.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/tk03.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_bottom"},[_c('p',[_vm._v("版权所有")]),_c('p',[_vm._v("本网站的图文和软件来自于网络，我们尊重他人的合法权益，如有内容侵犯您的合法权益，请及时与我们联系，我们将第一时间安排删除。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot_title"},[_c('img',{attrs:{"src":require("../assets/tk.png"),"alt":""}}),_c('h2',{staticStyle:{"color":"black","font-weight":"400"}},[_vm._v("抖音国际版正版下载-中文电脑版下载")])])
}]

export { render, staticRenderFns }
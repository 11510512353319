<template>
    <div class="con">
        <div class="search_title">
            <i class="el-icon-back" @click="back"></i>
            <p>订单详情</p>
        </div>
        <div class="result_box" v-if="result">
            <p>订单号：</p>
            <p class="result_item">{{result.trade}}</p>
            <p style="margin-top: 15px">下载链接(点击链接下载)：</p>
            <a class="result_item" style="color: #409EFF" @click="down">{{result.url}}</a>
            <div v-if="result.has_acc==1">
                <p style="margin-top: 15px">账号信息：</p>
                <div v-if="result.account">
                    <a class="result_item">{{result.account}}</a>
                </div>
                <div v-else>
                    <p class="result_item">待发货,请耐心等待!</p>
                </div>
            </div>
        </div>
        <div class="result_box" v-else>
            <p>暂无订单记录！</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Result",
        data(){
            return {
                result: ""
            }
        },
        created(){
            const aa = this.$route.query.trade;
            if(aa!=undefined){
                this.trade = aa;
                this.getData();
            }
        },
        methods:{
            back(){
                this.$router.push({
                    name: "home"
                })
            },
            down(){
                if(this.result != ""){
                    window.location.href = this.result.url;
                }
            },
            async getData(){
                const res = await this.request.post("/api/search",{trade: this.trade});
                if(res.code == 1){
                    this.result = res.data;
                }
            }
        }
    }
</script>

<style scoped>
    .search_title{
        position: relative;
        width: 100%;
        height: 50px;
        border-bottom: 1px solid silver;
    }

    .search_title p{
        text-align: center;
        line-height: 50px;
    }

    .el-icon-back{
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
    }

    .result_box{
        box-sizing: border-box;
        width: calc(100% - 30px);
        margin: 15px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 20px 15px;
    }

    .result_box p{
        line-height: 30px;
        /*超出div自动换行*/
        white-space: normal;
        word-wrap: break-word;
    }

    .result_item{
        box-sizing: border-box;
        background: #E5E5E5;
        padding: 5px 10px;
        border-radius: 5px;
        margin-top: 3px;
    }
</style>